import { ShopCar } from './car-shop-types'

export default function getAllUsedValues(shopCars: ShopCar[], keyPath: string[]): string[] {
    return shopCars.reduce((values: string[], value: any) => {
        keyPath.forEach((key: string) => {
            value = value[key]
        })

        if (!values.includes(value)) {
            return [...values, value]
        }

        return values
    }, [])
}
