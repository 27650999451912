import { getLowestPrice } from './get-lowest-price'
import { ShopCar } from './car-shop-types'

export interface CarShopFiltersInterface {
    color: string
    model: string
    offerType: string
    sort: string
}

export default function filterShopCars(shopCars: ShopCar[], filters: CarShopFiltersInterface): ShopCar[] {
    const { color, model, offerType, sort } = filters

    return shopCars
        .filter((shopCar: ShopCar) => {
            return (
                [shopCar.model.name.replace(/\s/g, '+').toLowerCase(), 'all'].includes(model) &&
                [shopCar.simpleColor, 'all'].includes(color) &&
                shopCar.offers.some(({ __typename }: { __typename: string }) => __typename === offerType)
            )
        })
        .sort((shopCarA: ShopCar, shopCarB: ShopCar) => {
            const priceA = getLowestPrice(shopCarA, offerType) || 0
            const priceB = getLowestPrice(shopCarB, offerType) || 0

            if (sort === 'price-asc') {
                return priceA - priceB
            } else if (sort === 'price-desc') {
                return priceB - priceA
            } else if (sort === 'available-asc') {
                return (new Date(shopCarA.availabilityDate) as any) - (new Date(shopCarB.availabilityDate) as any)
            } else if (sort === 'available-desc') {
                return (new Date(shopCarB.availabilityDate) as any) - (new Date(shopCarA.availabilityDate) as any)
            }

            return 0
        })
}
