import Image, { ImageVersion } from '../craft-cms/image'
import React from 'react'
import styled from 'styled-components'

export interface CarShopIntroImage extends Array<CarShopIntroImage> {
    filename: string
    height: number
    width: number
    dateUpdated: string
}

interface CarShopIntroProps {
    introText: string
    introImages: CarShopIntroImage[]
}

const CarShopIntro = ({ introText, introImages }: CarShopIntroProps): JSX.Element => {
    return (
        <Banner>
            <TextBox dangerouslySetInnerHTML={{ __html: introText }} />
            {introImages.map((image: CarShopIntroImage, index: number) => {
                return (
                    <ImageContainer key={index}>
                        <Image data={{ image }} version={ImageVersion.Small} />
                    </ImageContainer>
                )
            })}
        </Banner>
    )
}

export default CarShopIntro

const oneImageSmall = '47rem'
const oneImage = '61rem'
const bothImages = '75rem'

const Banner = styled.div`
    display: flex;
`

const TextBox = styled.div`
    width: 100%;
    background: var(--color-black-light);
    color: var(--color-white);
    padding: 1.25rem 1.5rem;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    * {
        max-width: 34rem;
        font-size: var(--font-size-extra-small);
        color: inherit;
        margin: 0;
    }

    * + * {
        margin: 0.75rem 0 0;
    }

    a {
        text-decoration: underline;
    }

    @media (min-width: ${oneImageSmall}) {
        width: calc(60% - 0.75rem);

        * {
            font-size: var(--font-size-small);
        }
    }

    @media (min-width: ${oneImage}) {
        width: calc(70% - 0.75rem);
    }

    @media (min-width: ${bothImages}) {
        width: calc(54% - 1.5rem);
    }
`

const ImageContainer = styled.div`
    display: none;
    margin: 0 0 0 0.75rem;

    img {
        display: block;
        height: 100%;
        object-fit: cover;
    }

    @media (min-width: ${oneImageSmall}) {
        width: 40%;

        &:nth-of-type(2) {
            display: block;
        }
    }

    @media (min-width: ${oneImage}) {
        width: 30%;
    }

    @media (min-width: ${bothImages}) {
        display: block;
        width: 23%;
    }
`
