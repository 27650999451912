import { CarShopFiltersInterface } from '../../utilities/car-shop/filter-shop-cars'
import { CarShopLabels, CarShopType, ShopCar, ShopOfferType } from '../../utilities/car-shop/car-shop-types'
import getAllUsedValues from '../../utilities/car-shop/get-all-used-values'
import getCarShopRootPath from '../../utilities/car-shop/get-car-shop-root-path'
import getNewQueryString from '../../utilities/car-shop/get-new-query-string'
import getOfferTypeName from '../../utilities/car-shop/get-offer-type-name'
import getPrettyOfferTypeName from '../../utilities/car-shop/get-pretty-offer-type-name'
import { Link } from 'gatsby'
import { LocaleObject } from '../../utilities/general/types'
import { makeTitleCase } from '../../utilities/general/labels'
import MaterialSelect from '../ui/material-select'
import { navigate } from '@reach/router'
import queryString from 'query-string'
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

interface CarShopFiltersProps {
    availableShopOfferTypes: ShopOfferType[]
    carShopLabels: CarShopLabels
    carShopType: CarShopType
    locale: LocaleObject
    location: any
    onFilterChange(arg: CarShopFiltersInterface): void
    onOfferTypeChange(arg: string): void
    shopCars: ShopCar[]
}

const CarShopFilters = ({
    availableShopOfferTypes,
    carShopLabels,
    carShopType,
    locale,
    location,
    onFilterChange,
    onOfferTypeChange,
    shopCars,
}: CarShopFiltersProps): JSX.Element => {
    const queryObj = queryString.parse(location.search)
    const carShopRootPath = getCarShopRootPath({ carShopType, locale })

    const modelSelectables = [
        { name: carShopLabels.modelDefaultValue, value: 'all' },
        ...getAllUsedValues(shopCars, ['model', 'name']).map((model: string) => ({
            name: makeTitleCase(model),
            value: model.replace(/\s/g, '+').toLowerCase(),
        })),
    ]

    const colorSelectables = [
        { name: carShopLabels.colorDefaultValue, value: 'all' },
        ...getAllUsedValues(shopCars, ['simpleColor']).map((color: string) => ({ name: color, value: color })),
    ]

    const sortSelectables = [
        { value: 'price-asc', name: carShopLabels.sortingOptionPriceAscending },
        { value: 'price-desc', name: carShopLabels.sortingOptionPriceDescending },
        { value: 'available-asc', name: carShopLabels.sortingOptionAvailableFromAscending },
        { value: 'available-desc', name: carShopLabels.sortingOptionAvailableFromDescending },
    ]

    const [offerType, setOfferType] = useState(queryObj.offerType || '')
    const [model, setModel] = useState(queryObj.model || 'all')
    const [color, setColor] = useState(queryObj.color || 'all')
    const [sort, setSort] = useState(queryObj.sort || 'price-asc')

    useEffect(() => {
        const selectedOfferType = getOfferTypeName(availableShopOfferTypes, location.pathname)
        setOfferType(selectedOfferType)
        onOfferTypeChange(selectedOfferType)
    }, [location.pathname])

    useEffect(() => {
        if (queryObj['model']) {
            setModel((queryObj['model'] as string).toLowerCase())
        }

        if (queryObj['color']) {
            setColor(queryObj['color'] as string)
        }

        if (queryObj['sort']) {
            setSort(queryObj['sort'] as string)
        }
    }, [location.search])

    useEffect(() => {
        onFilterChange({
            color: color as string,
            model: model as string,
            offerType: offerType as string,
            sort: sort as string,
        })
    }, [offerType, model, color, sort])

    const handleFilterUpdate = (filter: string, newValue: string) => {
        const addition: { [key: string]: string } = {}
        addition[filter] = newValue
        navigate(getNewQueryString(location.search, addition))
    }

    const innerFilters = (
        <InnerAdditionalFilters>
            <SelectFilter>
                <MaterialSelect
                    currentValue={model as string}
                    label={carShopLabels.modelLabel}
                    onChange={value => handleFilterUpdate('model', value || 'all')}
                    selectables={modelSelectables}
                />
            </SelectFilter>
            <SelectFilter>
                <MaterialSelect
                    currentValue={color as string}
                    label={carShopLabels.colorLabel}
                    onChange={value => handleFilterUpdate('color', value || 'all')}
                    selectables={colorSelectables}
                />
            </SelectFilter>
            <SelectFilter>
                <MaterialSelect
                    currentValue={sort as string}
                    label={carShopLabels.sortingOptionsLabel}
                    onChange={value => handleFilterUpdate('sort', value || 'price-asc')}
                    selectables={sortSelectables}
                />
            </SelectFilter>
        </InnerAdditionalFilters>
    )

    return (
        <FiltersContainer>
            <div>
                <OfferTypeLabel>{carShopLabels.offerTypeLabel}</OfferTypeLabel>
                <OfferTypeList>
                    {availableShopOfferTypes.map((offerType: ShopOfferType) => {
                        return (
                            <OfferTypeListItem
                                key={offerType.name}
                                isSelected={[location.pathname, `${location.pathname}/`].includes(
                                    `${carShopRootPath}/${offerType.url}`
                                )}
                            >
                                <Link to={`${carShopRootPath}/${offerType.url}${location.search}`}>
                                    {getPrettyOfferTypeName(carShopLabels, offerType)}
                                </Link>
                            </OfferTypeListItem>
                        )
                    })}
                </OfferTypeList>
            </div>
            <DesktopFilters>{innerFilters}</DesktopFilters>
            <MobileFilters>
                <FiltersTitle>
                    <h2>Filters</h2>
                </FiltersTitle>
                {innerFilters}
            </MobileFilters>
        </FiltersContainer>
    )
}

export default CarShopFilters

const offerTypeOnOneLine = '40rem'
const allVisibleBreakpoint = '55rem'
const oneLineBreakpoint = '76rem'

const FiltersContainer = styled.div`
    margin: 1.25rem 0;

    @media (min-width: ${oneLineBreakpoint}) {
        display: flex;
        align-items: flex-end;
    }
`

const OfferTypeLabel = styled.p`
    color: var(--color-grey-extra-dark);
    font-family: var(--font-headings);
    font-size: 0.625rem;
    margin: 0 0 0.1875rem;
`

const OfferTypeList = styled.ul`
    list-style: none;
    padding: 0;
    border: 0.0625rem solid var(--color-black);
    border-radius: 0.25rem;
    margin: 0;

    @media (min-width: ${offerTypeOnOneLine}) {
        display: inline-flex;
    }
`

const OfferTypeListItem = styled.li<{ isSelected: boolean }>`
    a {
        display: block;
        color: inherit;
        font-size: 0.75rem;
        text-align: center;
        text-decoration: none;
        padding: 0.25rem 0.75rem;
        border-bottom: 0.0625rem solid var(--color-black);

        @media (min-width: ${offerTypeOnOneLine}) {
            border-bottom: 0;
            border-right: 0.0625rem solid var(--color-black);
        }

        &:focus {
            outline: none;
            background-color: rgba(0, 0, 0, 0.05);
        }

        &:hover {
            opacity: 0.75;
        }
    }

    &:last-of-type {
        a {
            border: 0;
        }
    }

    ${props =>
        props.isSelected &&
        css`
            background-color: var(--color-black);
            color: var(--color-white);

            a:focus {
                outline: none;
                opacity: 0.75;
            }
        `}
`

const DesktopFilters = styled.div`
    display: none;

    @media (min-width: ${allVisibleBreakpoint}) {
        display: block;
        flex-grow: 1;
        padding: 0;
        margin: 1.25rem 0 0;
    }

    @media (min-width: ${oneLineBreakpoint}) {
        margin: 0 0 0 1.25rem;

        &:first-child {
            margin: 0;
        }
    }
`

const MobileFilters = styled.details`
    flex-grow: 1;
    background-color: var(--color-white);
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 0.0625rem 0.5rem 0 rgba(0, 0, 0, 0.08);
    margin-top: 1.25rem;

    &[open] {
        padding-bottom: 1.5rem;

        summary h2::after {
            transform: rotate(-135deg);
        }
    }

    @media (min-width: ${offerTypeOnOneLine}) {
        max-width: 27rem;
    }

    @media (min-width: ${allVisibleBreakpoint}) {
        display: none;
    }
`

const FiltersTitle = styled.summary`
    list-style: none;
    cursor: pointer;

    ::-webkit-details-marker {
        display: none;
    }

    h2 {
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &::after {
            content: '';
            display: inline-block;
            padding: 0.1875rem;
            vertical-align: middle;
            transform: rotate(45deg);
            transform-origin: 50% 50%;
            border-style: solid;
            border-width: 0 0.125rem 0.125rem 0;
            transition: transform 0.15s ease-in-out;
        }
    }

    &:focus,
    &:hover {
        outline: none;
        opacity: 0.7;
    }
`

const InnerAdditionalFilters = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;

    @media (min-width: ${allVisibleBreakpoint}) {
        display: grid;
        grid-template-columns: repeat(3, calc((100% - 2.5rem) / 3));
        grid-gap: 1.25rem;
    }
`

const SelectFilter = styled.li`
    flex-grow: 1;
    margin-top: 0.75rem;

    @media (min-width: ${allVisibleBreakpoint}) {
        margin-top: 0;
    }
`
