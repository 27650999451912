import React from 'react'
import styled from 'styled-components'

const CarShopNoneFound = ({ text }: { text: string }): JSX.Element => (
    <Container>
        <Message>{text}</Message>
    </Container>
)

export default CarShopNoneFound

const Container = styled.div``

const Message = styled.p`
    max-width: 48rem;
    background-color: var(--color-green);
    color: var(--color-white);
    text-align: center;
    padding: 2rem;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
    margin: 0 auto;

    @media (min-width: 45rem) {
        margin: 2rem auto;
    }
`
