import queryString from 'query-string'

export default function getNewQueryString(currentQueryString: string, addition: { [key: string]: string }): string {
    const queryParams = queryString.parse(currentQueryString)
    return (
        '?' +
        queryString.stringify({
            ...queryParams,
            ...addition,
        })
    )
}
